




import Vue from "vue";

export default Vue.extend({
  name: "AccountLogo",
  props: {
    accountId: { type: String, required: true }
  },
  computed: {
    account(): AccountStoreEntry | undefined {
      return this.$store.direct.state.accounts.all[this.accountId];
    },
    logoUrl(): string | null {
      return this.account?.logoUrl ?? null;
    },
    title(): string {
      if (this.account?.logo) {
        return `${this.account.name} Logo`;
      }
      return `No logo for ${this.account?.name ?? "this account"}`;
    }
  },
  watch: {
    account(): void {
      if (this.account?.logo && !this.account.logoUrl) {
        void this.$store.direct.dispatch.loadLogo({
          accountId: this.accountId,
          type: "logoUrl",
          fileName: this.account.logo
        });
      }
    }
  }
});
