











































import Vue from "vue";

import AccountLogo from "@/components/AccountLogo.vue";
import InformationIcon from "@/icons/Information.vue";
import InvitationCodeIcon from "@/icons/InvitationCode.vue";
import KaijuIcon from "@/icons/Kaiju.vue";
import UsersIcon from "@/icons/Users.vue";

export default Vue.extend({
  name: "AccountView",
  components: {
    InformationIcon,
    InvitationCodeIcon,
    AccountLogo,
    KaijuIcon,
    UsersIcon
  },
  props: { accountId: { type: String, required: true } },
  computed: {
    account(): AccountStoreEntry | undefined {
      return this.$store.direct.state.accounts.all[this.accountId];
    }
  },
  created() {
    void this.$store.direct.dispatch.watchAccount(this.accountId);
  }
});
